import * as React from "react"
import styled from 'styled-components'
import { fontsizes,media } from "../lib/style-utils"

import Cta from "../components/buttons/CTA"
import Blocks from "../components/Icons/Blocks"

// Styles
const IntroWrapper = styled.div``

const IntroInner = styled.p`
    max-width: 640px;
    margin: 0 auto;
    font-size: ${fontsizes.fs_text_normal_mob};
    line-height: ${fontsizes.lh_text_normal_mob};
    ${media.tablet`
        font-size: ${fontsizes.fs_text_normal};
        line-height: ${fontsizes.lh_text_normal};
    `}
`

const CtaWrapper = styled.div`
    z-index: 800;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    ${media.lgDesktop`
        position: absolute;
        bottom: auto;
        top: 0;
        right: 4%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;    
    `}
`

// Markup
const Introduction = (data) => {
  
    // all vacatures
    const IntroData = data.data;
    
    return (
        <IntroWrapper className="relative">
            <div className="container">
                <div className="px-4 py-8 md:py-16">
                    <IntroInner>
                        {IntroData}
                    </IntroInner>
                </div>
            </div>
            <CtaWrapper>
                <Cta
                    text="Solliciteer nu"
                    to="#solliciteren"/>
            </CtaWrapper>
        </IntroWrapper>
    )
}

export default Introduction